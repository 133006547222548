// Function to calculate the final product price based on user type
import { getDiscountPrice } from '../helpers/product';
const calculateFinalProductPrice = (cartItem, user) => {
    if (user?.type === "Retailer") {
        return cartItem?.retailerPrice || cartItem?.price;
    } else if (user?.type === "Dropshipper") {
        return cartItem?.dropshipperPrice || cartItem?.price;
    }
    return cartItem?.price;
};

// Function to calculate the final discount based on user type
const calculateFinalDiscount = (cartItem, user) => {
    if (user?.type === "Retailer") {
        return cartItem?.retailerDiscount || cartItem?.discount || 0;
    } else if (user?.type === "Dropshipper") {
        return cartItem?.dropshipperDiscount || cartItem?.discount || 0;
    }
    return cartItem?.discount || 0;
};

// Function to calculate the discounted price
const calculateDiscountedPrice = (price, discount) => {
    return getDiscountPrice(price, discount);
};

// Function to compute total values
const computeTotals = (retailerCart, user, currency) => {
    let tierTemp = 0;
    let CartTotalDiscountedPriceTemp = 0;
    let CartTotalPriceTemp = 0;
    let totalDiscountTemp = 0;

    retailerCart.forEach(item => {
        item?.cartItems.forEach(cartItem => {
            const finalProductPrice = calculateFinalProductPrice(cartItem, user);
            const finalDiscount = calculateFinalDiscount(cartItem, user);
            const discountedPrice = calculateDiscountedPrice(finalProductPrice, finalDiscount);
            const finalDiscountedPrice = +(discountedPrice * currency.currencyRate)?.toFixed(2);

            if (!cartItem.retailerDiscount) {
                tierTemp += (cartItem?.retailerPrice / user?.tier?.percentage) * cartItem?.quantity;
            }

            CartTotalDiscountedPriceTemp += (finalDiscountedPrice * cartItem.quantity);
            CartTotalPriceTemp += finalProductPrice * cartItem.quantity;
            totalDiscountTemp += (finalProductPrice - finalDiscountedPrice) * cartItem.quantity;
        });
    });

    return {
        CartTotalDiscountedPriceTemp,
        CartTotalPriceTemp,
        tierTemp,
        totalDiscountTemp
    };
};
const productPriceAfterDiscount=(cartItem,user,currency)=>{
    const finalProductPrice = calculateFinalProductPrice(cartItem, user);
    const finalDiscount = calculateFinalDiscount(cartItem, user);
    const discountedPrice = calculateDiscountedPrice(finalProductPrice, finalDiscount);
    const finalDiscountedPrice = +(discountedPrice * currency.currencyRate)?.toFixed(2);
    return finalDiscountedPrice
}
export {computeTotals,productPriceAfterDiscount};