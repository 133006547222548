import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { Autoplay, Pagination } from "swiper";
import { Link } from 'react-router-dom';

const Category = () => {

    const { brands } = useSelector((state) => state.brand)

    const filter = brands.filter((brand) => brand.photo.length > 0);

    return (
        <>
            <div className='my-5'>
                <Swiper
                    slidesPerView={6}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    loop={true}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        // when window width is >= 320px
                        320: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        },
                        // when window width is >= 1200px
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 30
                        },
                    }}
                >
                    {
                        filter.map((brand) => (
                            <SwiperSlide key={brand.id}>
                                <div className='swiper-item'>
                                    <div className='category-item'>
                                        <Link to={`/products?brand=${brand.slug}`}>
                                            <img className='swiper-category' src={brand.photo[0]} alt='category-img' />
                                        </Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </>
    )
}

export default Category