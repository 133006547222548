import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import GoogleMap from "../../components/google-map"

const StoreLocation = () => {
    let { pathname } = useLocation();

    return (
        <Fragment>
            <SEO
                titleTemplate="Store Location"
                description="Store Location page of Remax Wireless."
            />
            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Store Location", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="contact-area pt-100 pb-100">
                    <div className="contact-map mb-10">
                        <GoogleMap lat={29.7194707} lng={-95.5131079} />
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

export default StoreLocation;
