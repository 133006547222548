import React, { Fragment } from 'react'
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom"
import Category from "../../components/home/Category";

const Brands = () => {

    let { pathname } = useLocation();


    return (
        <Fragment>
            <SEO
                titleTemplate="Brands | Remax"
                description="Brands page of  Remax."
            />
            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1" >
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Brands", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="container">
                    <Category type="brand" />
                </div>
            </LayoutOne>
        </Fragment>

    )
}


export default Brands