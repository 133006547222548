import { setmodels } from "../slices/model-slice";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_HOST;

export const fetchModels = (options={}) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/model`,{ params: options });
      dispatch(setmodels(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
