import { createSlice } from '@reduxjs/toolkit';

const addressSlice = createSlice({
    name: 'store',
    initialState: {
        address: [],
    },
    reducers: {
        setAddress: (state, action) => {
            state.address = action.payload;
        },
        addNewAddress: (state, action) => {
            state.address.push(action.payload);
        },
        updateAddress: (state, action) => {
            const index = state.address.findIndex((address) => address.id === action.payload.id);
            if (index !== -1) {
                state.address[index] = action.payload.updatedAddress;
            }
        },
        deleteAddressById: (state, action) => {
            state.address = state.address.filter(address => address.id !== action.payload);
        }
    },
});

export const { setAddress, addNewAddress, updateAddress, deleteAddressById } = addressSlice.actions;
export default addressSlice.reducer;
