import React, { Fragment, useEffect } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PulseLoader from "../../components/ImageInputFiled/PulseLoader";
import cogoToast from "cogo-toast";

const VerifyEmail = () => {
  let { pathname } = useLocation();
  const redirect = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { token } = useParams();
  const [error, setError] = React.useState(false);

  useEffect(() => {
    if (!token) return redirect("/login");

    const verifyEmail = async () => {
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_HOST}/auth/verify-email`, {
          token,
        })
        .then((res) => {
          cogoToast.success(res?.data?.message, { position: "bottom-left" });
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          cogoToast.error(err?.response?.data?.message, {
            position: "bottom-left",
          });
          setLoading(false);
        });
    };

    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Fragment>
      <SEO
        titleTemplate="Email Verification | Remax Wireless"
        description="Email Verification page of Remax Wireless."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "Email Verification",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-5 py-5">
            <PulseLoader />
          </div>
        ) : (
          <div className="container">
            <div className="row justify-content-center align-items-center my-5 py-5">
              {error ? (
                <div className="col-md-6 text-center">
                  <h1>Something went wrong.</h1>
                  <p>Please try again later.</p>
                  <div className="slider-btn slider-btn--style2 btn-hover">
                    <Link
                      className="animated rounden-btn p-2"
                      to={process.env.PUBLIC_URL + "/"}
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      Back To Home
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="col-md-6 text-center">
                  <h1>
                    <video
                      src="/lottie.mp4"
                      loop
                      autoPlay
                      className="img-fluid"
                      style={{ mixBlendMode: "multiply" }}
                      width={"50%"}
                    />
                  </h1>
                  <p>Your email has been verified successfully.</p>
                  <Link to="/">
                    <div className="slider-btn slider-btn--style2 btn-hover">
                      <Link
                        className="animated rounden-btn p-2"
                        to={process.env.PUBLIC_URL + "/login"}
                        style={{
                          border: "1px solid black",
                        }}
                      >
                        Login To Continue
                      </Link>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </LayoutOne>
    </Fragment>
  );
};

export default VerifyEmail;
