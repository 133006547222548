const { createSlice } = require('@reduxjs/toolkit');

const subcategorySlice = createSlice({
    name: 'subcategory',
    initialState: {
        subcategories: [],
    },
    reducers: {
        setSubCategories(state, action) {
            state.subcategories = action.payload;
        }
    },
});

export const { setSubCategories } = subcategorySlice.actions;
export default subcategorySlice.reducer;
