import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MobileNavMenu = () => {

  const { t } = useTranslation();
  const { categories } = useSelector((state) => state.category);
  const { brands } = useSelector((state) => state.brand);
  const { carriers } = useSelector((state) => state.carrier);

  const navMenu = [
    {
      id: 1,
      category: t("Home"),
      link: "/",
      image: "/assets/img/menu/3.png",
    },
    {
      id: 2,
      category: t("New Arrivals"),
      link: "/new-arrivals",
      image: "/assets/img/menu/2.png",
    },
    {
      id: 3,
      category: t("Categories"),
      image: "/assets/img/menu/1.png",
      mainLink: "category",
      submenu: categories
    },
    {
      id: 4,
      category: t("Brands"),
      image: "/assets/img/menu/6.png",
      mainLink: "brand",
      submenu: brands
    },
    {
      id: 5,
      category: t("Carriers"),
      image: "/assets/img/menu/5.png",
      mainLink: "carrier",
      submenu: carriers
    },
    {
      id: 6,
      category: t("Clearnce"),
      // link: "/clearnce",
      link: "/clearance",
      image: "/assets/img/menu/4.png",
    },
    {
      id: 7,
      category: t("Contact Us"),
      link: "/contact",
      image: "/assets/img/menu/7.png",
    },

  ]

  const renderMenu = (menu) => {
    return (
      <ul>
        {
          menu.map((item, index) => {
            return (
              <li key={index} className="menu-item-has-children">
                {
                  item.link ? (
                    <Link to={process.env.PUBLIC_URL + item.link}>
                      {item.category}
                    </Link>
                  ) : (
                    <div style={{
                      fontWeight: '600',
                      display: 'block',
                      padding: '10px 0',
                      color: '#111',
                      textTransform: 'uppercase',
                    }}

                    >
                      {item.category}
                    </div>
                  )
                }
                {
                  item.submenu && (
                    <ul className="sub-menu">
                      {item.submenu.map((subitem, index) => {
                        return (
                          <li key={index}>
                            <Link to={process.env.PUBLIC_URL + `/products?${item.mainLink}=${subitem.slug}`}>
                              {subitem.name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )
                }
              </li>
            )
          })
        }
      </ul >
    )
  }



  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {renderMenu(navMenu)}
    </nav>
  );

  // return (
  //   <nav className="offcanvas-navigation" id="offcanvas-navigation">
  //     <ul>
  //       <li>
  //         <Link to={process.env.PUBLIC_URL + "/"}>
  //           Home
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/shop"}>
  //           Shop
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/categories"}>
  //           Categories
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/brands"}>
  //           Brands
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/shop"}>
  //           Carriers
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/shop"}>
  //           Clearnce
  //         </Link>
  //         <Link to={process.env.PUBLIC_URL + "/contact"}>
  //           Contact Us
  //           {/* {t("contact_us")} */}
  //         </Link>
  //       </li>
  //     </ul>
  //   </nav>
  // );
};

export default MobileNavMenu;
