import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getDiscountPrice } from '../../helpers/product';
import { Link } from 'react-router-dom';
import { decreaseQuantity, decreaseRetailerQuantity, deleteFromCart, increaseQuantity, increaseRetailerQuantity, removeFromRetailerCart } from '../../store/slices/cart-slice';

const ConsumerCartSummaryList = ({
    cartItems,
    handleSelectCarts,
    tierDisount,
    cartTotalDiscountedPrice,
    cartTotalPrice,
    totalDiscount,
    setTierDisount,
    setCartTotalDiscountedPrice,
    setCartTotalPrice,
    setTotalDiscount,
    wishlistItems,
    addToWishlist }) => {
    const { address } = useSelector((state) => state.store);
    const { user } = useSelector((state) => state.auth);
    const currency = useSelector((state) => state.currency);
    const dispatch = useDispatch()

    useEffect(() => {
        let tierTemp = 0
        let CartTotalDiscountedPriceTemp = 0
        let CartTotalPriceTemp = 0
        let totalDiscountTemp = 0
        cartItems.map((cartItem, index) => {
            const finalProductPrice =
                user?.type === "Retailer" ? cartItem?.retailerPrice || cartItem?.price : user?.type === "Dropshipper" ? cartItem?.dropshipperPrice : cartItem?.price;
            const finalDiscount =
                user?.type === "Retailer" ? cartItem?.retailerDiscount || cartItem?.discount || 0 : user?.type === "Dropshipper" ? cartItem?.dropshipperDiscount || cartItem?.discount : cartItem?.discount;
            const discountedPrice = getDiscountPrice(finalProductPrice, finalDiscount);
            const finalDiscountedPrice = +(discountedPrice * currency.currencyRate)?.toFixed(2);
            if (!cartItem.discount) {
                tierTemp += (cartItem?.price / user?.tier?.percentage) * cartItem?.quantity
            }
            CartTotalDiscountedPriceTemp += (finalDiscountedPrice * cartItem.quantity)
            CartTotalPriceTemp += finalProductPrice * cartItem.quantity

            totalDiscountTemp += (finalProductPrice - finalDiscountedPrice) * cartItem.quantity
        })
        setCartTotalDiscountedPrice(CartTotalDiscountedPriceTemp)
        setCartTotalPrice(CartTotalPriceTemp)
        setTierDisount(tierTemp)
        setTotalDiscount(totalDiscountTemp)
    }, [cartItems])
    return (
        <div className="card my-2 p-2">

            <div className="table-content table-responsive cart-table-content">
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Unit Price</th>
                            <th>Qty</th>
                            <th>Subtotal</th>
                            <th>Whislist</th>
                            <th>action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cartItems?.map((cartItem, key) => {
                                const finalProductPrice =
                                    user?.type === "Retailer" ? cartItem?.retailerPrice || cartItem?.price : user?.type === "Dropshipper" ? cartItem?.dropshipperPrice : cartItem?.price;
                                const finalDiscount =
                                    user?.type === "Retailer" ? cartItem?.retailerDiscount || cartItem?.discount || 0 : user?.type === "Dropshipper" ? cartItem?.dropshipperDiscount || cartItem?.discount : cartItem?.discount;

                                const discountedPrice = getDiscountPrice(finalProductPrice, finalDiscount);
                                const finalDiscountedPrice = +(discountedPrice * currency.currencyRate)?.toFixed(2);


                                return (
                                    <tr key={key}>
                                        <td className="product-thumbnail">
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/product/" +
                                                    cartItem.slug
                                                }
                                            >
                                                <img
                                                    className="img-fluid"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        cartItem.images[0]
                                                    }
                                                    alt=""
                                                />
                                            </Link>
                                        </td>

                                        <td className="product-name">
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/product/" +
                                                    cartItem.slug
                                                }
                                            >
                                                {cartItem.name}
                                            </Link>
                                            {cartItem.selectedProductColor &&
                                                cartItem.selectedProductSize ? (
                                                <div className="cart-item-variation">
                                                    <span>
                                                        Color: {cartItem.selectedProductColor}
                                                    </span>
                                                    <span>
                                                        Size: {cartItem.selectedProductSize}
                                                    </span>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </td>

                                        <td className="product-price-cart">
                                            {discountedPrice !== null ? (
                                                <Fragment>
                                                    <span className="amount old">
                                                        {currency.currencySymbol +
                                                            finalProductPrice}
                                                    </span>
                                                    <span className="amount">
                                                        {currency.currencySymbol +
                                                            finalDiscountedPrice}
                                                    </span>
                                                </Fragment>
                                            ) : (
                                                <span className="amount">
                                                    {currency.currencySymbol +
                                                        finalProductPrice}
                                                </span>
                                            )}
                                        </td>

                                        <td className="product-quantity">
                                            <div className="cart-plus-minus">
                                                <button
                                                    className="dec qtybutton"
                                                    onClick={() => {

                                                        user?.type === "Retailer" ? dispatch(decreaseRetailerQuantity(cartItem)) : dispatch(decreaseQuantity(cartItem))

                                                    }
                                                    }
                                                >
                                                    -
                                                </button>
                                                <input
                                                    className="cart-plus-minus-box"
                                                    type="text"
                                                    value={cartItem.quantity}
                                                    readOnly
                                                />
                                                <button
                                                    className="inc qtybutton"
                                                    onClick={() => {
                                                        if (cartItem !== undefined &&
                                                            cartItem.quantity < cartItem.stock
                                                        ) {
                                                            user?.type === "Retailer" ? dispatch(increaseRetailerQuantity(cartItem)) : dispatch(increaseQuantity(cartItem))
                                                        }
                                                    }
                                                    }
                                                    disabled={
                                                        cartItem !== undefined &&
                                                        cartItem.quantity >= cartItem.stock
                                                    }
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="product-subtotal">
                                            {discountedPrice !== null
                                                ? currency.currencySymbol +
                                                (
                                                    finalDiscountedPrice * cartItem.quantity
                                                )?.toFixed(2)
                                                : currency.currencySymbol +
                                                (
                                                    finalProductPrice * cartItem.quantity
                                                )?.toFixed(2)}
                                        </td>

                                        <td className="product-wishlist-cart">
                                            <button
                                                className={
                                                    wishlistItems.includes(cartItem)
                                                        ? "active"
                                                        : ""
                                                }
                                                disabled={wishlistItems.includes(cartItem)}
                                                title={
                                                    wishlistItems.includes(cartItem)
                                                        ? "Added to wishlist"
                                                        : "Add to wishlist"
                                                }
                                                onClick={() => dispatch(addToWishlist(cartItem))}
                                            >
                                                {wishlistItems.includes(cartItem)
                                                    ? "Added"
                                                    : "Add to wishlist"}
                                            </button>
                                        </td>

                                        <td className="product-remove">
                                            <button
                                                onClick={() => { user?.type === "Retiler" ? dispatch(deleteFromCart(cartItem.cartItemId)) : dispatch(removeFromRetailerCart(cartItem.cartItemId)); }
                                                }
                                            >
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default ConsumerCartSummaryList