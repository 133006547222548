import React, { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";


const ReturnPolicy = () => {
    let { pathname } = useLocation();


    return (
        <Fragment>
            <SEO
                titleTemplate="Return Policy | Remax "
                description="Return Policy page of  Remax Wireless."
            />
            <LayoutOne
                headerTop="visible"
                headerContainerclassName="container-fluid"
                headerPaddingclassName="header-padding-1"
            >
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Return & Exchange Policy", path: process.env.PUBLIC_URL + pathname },
                    ]}
                />
                <section className="login-main-wrapper">
                    <div className="main-container bg-white py-5">
                        <div className="login-process">
                            <div className="container my-3">
                                <h3>Return & Exchange Policy</h3>
                                <p>
                                    Thank you for shopping at Remax. We want you to be completely satisfied with your purchase. If you are not entirely happy with your order, we're here to help. This Returns Policy outlines our guidelines for returning products.
                                </p>
                                <h3>
                                    Eligibility for Returns
                                </h3>
                                <p>
                                    To be eligible for a return, the item must be in its original condition, unused, and in the same packaging as you received it.
                                    <br></br>
                                    Returns must be initiated within 7 days from the date of delivery.
                                </p>
                                <h3>
                                    Items Excluded from Returns
                                </h3>
                                <p>
                                    The following items are not eligible for return:
                                    <ol>
                                        <li>
                                            Phone cases
                                        </li>
                                        <li>
                                            Tempered Glass
                                        </li>
                                    </ol>
                                </p>
                                <h3>
                                    Initiating a Return
                                </h3>
                                <p>
                                    Contact our customer support at support@remax.com or (713) - 517 - 7861 within the return period to request a return authorization.
                                    <br />
                                    Provide the following information:
                                    <ul>
                                        <li>
                                            Order number
                                        </li>
                                        <li>
                                            Reason for return

                                        </li>
                                        <li>
                                            Description and condition of the item
                                        </li>
                                    </ul>

                                    Our customer support team will guide you through the return process and provide you with a return shipping label if applicable.
                                </p>
                                <h3>
                                    Return Shipping
                                </h3>
                                <p>
                                    For non-defective returns, the customer is responsible for the return shipping costs.
                                    If the return is due to our error (e.g., incorrect item shipped), we will cover the return shipping costs.
                                </p>
                                <h3>
                                    Refunds
                                </h3>
                                <p>
                                    Once we receive the returned item and inspect it to ensure it meets our return criteria, we will process the refund to the original payment method used during the purchase. <br></br>
                                    Please allow 14 days for the refund to be reflected in your account.

                                </p>
                                <h3>
                                    Exchanges
                                </h3>
                                <p>
                                    We currently do not offer direct exchanges. If you need a different size, color, or item, please initiate a return for the original item and place a new order.
                                </p>
                                <h3>
                                    Damaged or Defective Items
                                </h3>
                                <p>
                                    If you receive a damaged or defective item, please contact our customer support immediately with photos of the item and the packaging. We will arrange for a replacement or refund as appropriate.
                                </p>
                                <h3>
                                    Contact Us
                                </h3>
                                <p>
                                    If you have any questions or concerns about our returns policy, please contact us at:
                                    <br /><br />
                                    Remax Customer Support <br />
                                    Email: <a href="mailto:support@remax.com">support@remax.com </a><br />
                                    Phone: <a href="tel:+17135177861">(713) - 517 - 7861 </a><br />

                                </p>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </div>
                </section>
            </LayoutOne>
        </Fragment>
    );
};

export default ReturnPolicy;
