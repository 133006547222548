import React, { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";


const ShippingPolicy = () => {
    let { pathname } = useLocation();


    return (
        <Fragment>
            <SEO
                titleTemplate="Return Policy | Remax "
                description="Return Policy page of  Remax Wireless."
            />
            <LayoutOne
                headerTop="visible"
                headerContainerclassName="container-fluid"
                headerPaddingclassName="header-padding-1"
            >
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Shipping Policy", path: process.env.PUBLIC_URL + pathname },
                    ]}
                />
                <section className="login-main-wrapper">
                    <div className="main-container bg-white py-5">
                        <div className="login-process">
                            <div className="container my-3">
                                <h3>Shipping Policy for Remax</h3>
                                <p>
                                    Thank you for shopping at Remax! We are committed to providing you with a seamless shopping experience, including timely delivery of your orders. This Shipping Policy outlines important information regarding our shipping practices.
                                </p>
                                <h3>
                                    Order Processing
                                </h3>
                                <p>
                                    Orders are typically processed within 2 days after the order is placed. Please note that processing time may vary during peak seasons or holidays.
                                </p>
                                <h3>
                                    Shipping Methods
                                </h3>
                                <p>
                                    We offer various shipping options to cater to your needs. Available shipping methods and estimated delivery times will be displayed at checkout.
                                    <br></br>
                                    Standard shipping typically takes [standard shipping time], while expedited shipping options may be available for faster delivery.

                                </p>
                                <h3>
                                    Shipping Costs
                                </h3>
                                <p>
                                    Shipping costs are calculated based on the weight of the package, the shipping method selected, and the destination.
                                    You can view the shipping costs for your order during the checkout process before finalizing the purchase.

                                </p>
                                <h3>
                                    Order Tracking
                                </h3>
                                <p>
                                    Once your order is shipped, you will receive a shipping confirmation email containing a tracking number and instructions on how to track your package.
                                    You can also track your order's status by logging in to your Remax account and navigating to the "Order History" section.

                                </p>
                                <h3>
                                    Delivery Times
                                </h3>
                                <p>
                                    Delivery times depend on the shipping method selected and the destination address.
                                    Please note that delivery times may be impacted by factors beyond our control, such as weather conditions, customs delays, or carrier-related issues.
                                    Shipping Restrictions.
                                </p>
                                <h3>
                                    Order Changes and Cancellations
                                </h3>
                                <p>
                                    Once an order has been placed, changes to the shipping address or order contents may not be possible. If you need to make any modifications, please contact our customer support as soon as possible.
                                </p>
                                <h3>
                                    Lost or Damaged Packages
                                </h3>
                                <p>
                                    In the event that your package is lost or damaged during transit, please contact our customer support immediately. We will work with the carrier to resolve the issue and ensure your satisfaction.
                                </p>
                                <h3>
                                    Contact Us
                                </h3>
                                <p>
                                    If you have any questions or concerns about our returns policy, please contact us at:
                                    <br /><br />
                                    Remax Customer Support <br />
                                    Email: <a href="mailto:support@remax.com">support@remax.com </a><br />
                                    Phone: <a href="tel:+17135177861">(713) - 517 - 7861 </a><br />

                                </p>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </div>
                </section>
            </LayoutOne>
        </Fragment>
    );
};

export default ShippingPolicy;
