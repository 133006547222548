import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ProductgridList from "./ProductgridList";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreProducts } from '../../store/actions/productActions';



const ShopProducts = ({ products, layout }) => {
  const { loading } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const fetchMore = () => {
    setPage(page + 1);
    dispatch(fetchMoreProducts(page, 40));
  }

  return (
    <div className="shop-bottom-area mt-35">
      <div className={clsx("row", layout)}>
        <ProductgridList products={products} spaceBottomClass="mb-25" />
        {
          products.length >= 40 && (<>
            {
              products.length > 0 && (<>{
                loading ? (
                  <div className="col-12 text-center">
                    <button className="load-more-btn" disabled onClick={fetchMore}>Loading...</button>
                  </div>
                ) : (
                  <div className="col-12 text-center">
                    <button className="load-more-btn" onClick={fetchMore}>Load More</button>
                  </div>
                )
              }</>)
            }
          </>)
        }

      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array
};

export default ShopProducts;
