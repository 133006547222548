import { setbrands } from "../slices/brand-slice";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_HOST

export const fetchBrands = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${API_URL}/brand`);
            dispatch(setbrands(response.data));
        } catch (error) {
            console.log(error);
        }
    };
};