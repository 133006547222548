import React, { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useLocation } from "react-router-dom";


const PrivacyPolicy = () => {
    let { pathname } = useLocation();


    return (
        <Fragment>
            <SEO
                titleTemplate="Return Policy | Remax "
                description="Return Policy page of  Remax Wireless."
            />
            <LayoutOne
                headerTop="visible"
                headerContainerclassName="container-fluid"
                headerPaddingclassName="header-padding-1"
            >
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Privacy Policy", path: process.env.PUBLIC_URL + pathname },
                    ]}
                />
                <section className="login-main-wrapper">
                    <div className="main-container bg-white py-5">
                        <div className="login-process">
                            <div className="container my-3">
                                <h3>Privacy Policy for Remax</h3>
                                <p>
                                    At Remax, we value and respect the privacy of our users. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website. By accessing or using our website, you agree to the terms of this Privacy Policy.
                                </p>
                                <h3>
                                    Information We Collect
                                </h3>
                                <p>
                                    We may collect personal information from you when you voluntarily provide it to us. This information may include: <br /><br />

                                    Name <br></br>
                                    Email address <br></br>
                                    Contact information <br></br>
                                    Billing and shipping address <br></br>
                                    Payment details (credit card information) <br></br>
                                    Additionally, we may collect non-personal information, such as your IP address, browser type, and device information, to improve our website's functionality and user experience.

                                </p>
                                <h3>
                                    Use of Information
                                </h3>
                                <p>
                                    We use the collected information for the following purposes: <br></br><br></br>

                                    To process and fulfill your orders for products sold by us.<br></br>
                                    To provide customer support and respond to inquiries.<br></br>
                                    To improve our website and product offerings based on user preferences.<br></br>
                                    To send promotional offers, newsletters, and updates about our products and services (you can opt-out of these communications at any time).<br></br>
                                    To conduct research and analysis for business improvement.<br></br>
                                    Data Security<br></br><br></br>

                                    We take the security of your personal information seriously and implement reasonable technical and organizational measures to protect it from unauthorized access, loss, misuse, or alteration. However, please note that no data transmission over the internet or electronic storage method is completely secure.

                                </p>
                                <h3>
                                    Cookies and Tracking Technologies
                                </h3>
                                <p>
                                    We use cookies and similar tracking technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us remember your preferences, analyze site usage, and provide personalized content and advertisements. By using our website, you consent to the use of cookies.
                                </p>
                                <h3>
                                    Third-Party Disclosure
                                </h3>
                                <p>
                                    We do not sell, trade, or transfer your personal information to third parties without your explicit consent, except when necessary to fulfill your orders or comply with legal requirements.
                                </p>
                                <h3>
                                    Third-Party Links
                                </h3>
                                <p>
                                    Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.

                                </p>
                                <h3>
                                    Changes to Privacy Policy
                                </h3>
                                <p>
                                    We may update this Privacy Policy from time to time. The updated version will be posted on this page, and the revised date will be indicated at the top of the page. Please review this Privacy Policy periodically for any changes.
                                </p>
                                <h3>
                                    Contact Us
                                </h3>
                                <p>
                                    If you have any questions or concerns about our returns policy, please contact us at:
                                    <br /><br />
                                    Remax Customer Support <br />
                                    Email: <a href="mailto:support@remax.com">support@remax.com </a><br />
                                    Phone: <a href="tel:+17135177861">(713) - 517 - 7861 </a><br />

                                </p>
                            </div>
                        </div>
                        <div className="clr"></div>
                    </div>
                </section>
            </LayoutOne>
        </Fragment>
    );
};

export default PrivacyPolicy;
