const { createSlice } = require('@reduxjs/toolkit');

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        rawProducts: [],
        searchedProducts: [],
        filter: {},
        loading: false,
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        productRequest(state, action) {
            state.loading = true;
        },
        productSuccess(state, action) {
            state.products = [...state.products, ...action.payload];
            state.loading = false;
        },
        productFail(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        setFilter(state, action) {
            state.filter = action.payload;
            // state.products = action.payload.products;
        },
        setRawProducts(state, action) {
            state.rawProducts = action.payload;
        },
        setSearchedProducts(state, action) {
            state.searchedProducts = action.payload;
        },
        updateProductsQtyinCart(state, action) {
            state.products = state.products.map(product => {
                const cartItem = action.payload.find(item => item._id === product._id);
                if (cartItem) {
                    return {
                        ...product,
                        stock: cartItem.quantity
                    }
                }
                return product;
            }
            )
        }
    },
});

export const { setProducts, setFilter, productSuccess, productRequest, productFail, setRawProducts, setSearchedProducts, updateProductsQtyinCart } = productSlice.actions;
export default productSlice.reducer;
