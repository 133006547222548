
import { useState } from 'react';
import moment from 'moment';
import RetailerOrderDetails from './RetailerOrderDetails';
const OrderCard = ({ orders, statusOrder }) => {
  const [selectedOrder, setSelectedOrder] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = (id) => {
    setSelectedOrder(id);
    setShowDetails(!showDetails);
  };

  console.log(orders, 'orders');
  return (
    <>
      {orders.map((order, index) => (
        <div type="button" className="card bg-white shadow-sm p-3 cursor-pointer" key={index} onClick={() => toggleDetails(order._id)}>
          <div className="card-header d-flex justify-content-between align-items-center bg-white flex-column flex-lg-row">
            <h4 className="p-3 bg-gray shadow-sm rounded-pill">
              Order Id : <span className="text-primary">#{order.orderId}</span>
            </h4>
            <h4 className="text-center">
              Order Placed : <span>{moment(order.createdAt).calendar()}</span>
            </h4>
            <h4 className="text-center">
              Status
              <span className={`badge bg-${statusOrder[order.status]} rounded-pill ms-2`}>
                {order.status}
              </span>
            </h4>
            <h4 className="text-center">
              Is Paid
              <span className={`badge bg-${order.isPaid ? 'success' : 'danger'} rounded-pill ms-2`}>
                {order.isPaid ? 'YES' : 'NO'}
              </span>
            </h4>
          </div>
          {(showDetails && order._id === selectedOrder) && (
            <RetailerOrderDetails stores={order.stores} shipping={order.shippingCharges} />
          )}
        </div>
      ))}
    </>
  );
};

export default OrderCard;
