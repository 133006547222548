import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
// import FooterCopyright from "../../components/footer/FooterCopyright";
// import FooterNewsletter from "../../components/footer/FooterNewsletter";


const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const token = JSON.parse(localStorage.getItem("remax"));
  
  return (
    <footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass)}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          {/* <div
            className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-3 col-sm-4"
              }`}
          >
            <FooterCopyright
              footerLogo="/assets/img/logo/logo-dark.jpg"
              spaceBottomClass="mb-30"
            />
          </div> */}
          <div
            className={`${sideMenu ? "col-xl-4 col-sm-4" : "col-lg-4 col-sm-6"
              }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3><b>General</b></h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>About us</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/store-location"}>
                      Store location
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>
                      Contact
                    </Link>
                  </li>
                 {token.auth.isAuthenticated && <li>
                    <Link to={process.env.PUBLIC_URL + "/my-orders"}>
                      Orders tracking
                    </Link>
                  </li>}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${sideMenu ? "col-xl-4 col-sm-4" : "col-lg-4 col-sm-6"
              }`}
          >
            <div
              className={`${sideMenu
                ? "footer-widget mb-30 ml-95"
                : "footer-widget mb-30 ml-50"
                }`}
            >
              <div className="footer-title">
                <h3><b>Company</b></h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/return-exchnage-policy"}>Return & Exchange Policy</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shipping-policy"}>
                      Shipping Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "#/"}>FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${sideMenu ? "col-xl-4 col-sm-4" : "col-lg-4 col-sm-6"
              }`}
          >
            <div
              className={`${sideMenu
                ? "footer-widget mb-30 ml-145"
                : "footer-widget mb-30 ml-75"
                }`}
            >
              <div className="footer-title">
                <h3><b>Follow Us</b></h3>
              </div>
              <div className="footer-list">
                <a
                  href="//www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="//www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="//www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
            }`}
          >
            <div className="footer-title">
                <h3><b>Follow Us</b></h3>
              </div>

            
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-copy-right text-center">
              <p>
                &copy; 2024. Remax All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
