import PropTypes from "prop-types";

// import { setActiveLayout } from "../../helpers/product";

const ShopTopAction = ({
  // getLayout,
  setFilterSortParams,
  productCount,
  sortedProductCount
}) => {
  return (
    <div className="shop-top-bar mb-35">
      <div className="select-shoing-wrap">
        <div className="shop-select">
          <select
            onChange={e => setFilterSortParams(e.target.value)}
          >
            <option value="default">Default</option>
            <option value="nameAsc">Alphabatically - A to Z</option>
            <option value="nameDesc">Alphabatically - Z to A</option>
            <option value="priceHighToLow">Price - High to Low</option>
            <option value="priceLowToHigh">Price - Low to High</option>
          </select>
        </div>
        <p>
          Showing {sortedProductCount} of {productCount} result
        </p>
      </div>
    </div>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopAction;
