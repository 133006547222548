const { createSlice } = require('@reduxjs/toolkit');

const carrierSlice = createSlice({
    name: 'carrier',
    initialState: {
        carriers: [],
    },
    reducers: {
        setCarriers(state, action) {
            state.carriers = action.payload;
        }
    },
});

export const { setCarriers } = carrierSlice.actions;
export default carrierSlice.reducer;
