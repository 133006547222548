import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MobileSearch = () => {

  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/shop?search=${search}`);
    setSearch("");
  };
  return (
    <div className="offcanvas-mobile-search-area">
      <form onSubmit={handleSearch}>
      <input type="text" placeholder="Search..." onChange={handleChange} name="search" />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
