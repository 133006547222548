const { createSlice } = require('@reduxjs/toolkit');

const adsSlice = createSlice({
    name: "ad",
    initialState: {
        ads: [],
        loading: false,
    },
    reducers: {
        setAds(state, action) {
            state.ads = action.payload;
            state.loading = false;
        },
        loadingAds(state, action) {
            state.loading = true;
        },
    }
});

export const { setAds, loadingAds } = adsSlice.actions;
export default adsSlice.reducer;