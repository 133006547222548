const { createSlice } = require('@reduxjs/toolkit');

const designSlice = createSlice({
    name: 'designs',
    initialState: {
        designs: [],
    },
    reducers: {
        setdesigns(state, action) {
            state.designs = action.payload;
        }
    },
});

export const { setdesigns } = designSlice.actions;
export default designSlice.reducer;
