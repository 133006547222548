import {
  setProducts,
  setFilter,
  productSuccess,
  productRequest,
  productFail,
} from "../slices/product-slice";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_HOST;

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/products`);
      dispatch(setProducts(response.data));
    } catch (error) { }
  };
};

export const fetchMoreProducts = (page, limit) => {
  return async (dispatch) => {
    try {
      dispatch(productRequest());
      const response = await axios.get(
        `${API_URL}/products?page=${page}&limit=${limit}`
      );
      dispatch(productSuccess(response.data));
    } catch (error) {
      dispatch(productFail(error.response.data));
    }
  };
};

export const fetchProductTier = () => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("xat"),
        },
      };
      const response = await axios.get(
        `${API_URL}/products/tier-products`,
        config
      );
      dispatch(setProducts(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchFilteredProduct = (route) => {
  return async (dispatch) => {
    try {
      if (route === "blank") {
        const response = await axios.get(`${API_URL}/products/filter`);
        dispatch(setFilter(response.data));
      } else {
        const response = await axios.get(`${API_URL}/products/filter?${route}`);
        dispatch(setFilter(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMoreFilteredProduct = (route) => {
  return async (dispatch) => {
    try {
      // const response = await axios.get(`${API_URL}/products/filter?${route}`);
      // dispatch(setFilter(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};