import React, { useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";

const ShopPrice = ({ title, priceRange, setPriceRange }) => {
    const [openPrice, setOpenPrice] = useState(false);

    const handlePriceRangeChange = (e) => {
        const { name, value } = e.target;
        setPriceRange((prevRange) => {
            const updatedRange = [...prevRange];
            updatedRange[name === "min" ? 0 : 1] = parseFloat(value);
            return updatedRange;
        });
    };

    return (
        <div className="sidebar-widget">
            <h4 className="pro-sidebar-title"> </h4>
            <div className="sidebar-widget-list mt-30">
                <ul>
                    <li>
                        <div className={`sidebar-widget-list-left`}>
                            <motion.button
                                onClick={() => setOpenPrice(!openPrice)}
                                className="ps-2"
                            >
                                <p className="mb-0 fs-5">{title}</p>
                                <motion.span>
                                    {openPrice ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
                                </motion.span>
                            </motion.button>
                            <AnimatePresence>
                                {openPrice && (
                                    <motion.div
                                        initial={{ height: 0, opacity: 0 }}
                                        animate={{ height: "auto", opacity: 1 }}
                                        exit={{ height: 0, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className="submenu-container mt-30"
                                    >
                                        <div className="range-slider-react">
                                            <span className="rangeValues">
                                                ${priceRange[0]} - ${priceRange[1]}
                                            </span>
                                            <input
                                                name="min"
                                                value={priceRange[0]}
                                                className="min-range"
                                                min="1"
                                                max="10000"
                                                step="1"
                                                type="range"
                                                onChange={handlePriceRangeChange}
                                            />
                                            <input
                                                name="max"
                                                value={priceRange[1]}
                                                min="1"
                                                max="10000"
                                                step="1"
                                                type="range"
                                                onChange={handlePriceRangeChange}
                                            />
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ShopPrice;
