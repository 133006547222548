import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import ShopCategoriesFilter from "./ShopCategoriesFilter";
import ShopPrice from "./ShopPrice";
import { setmodels } from "../../store/slices/model-slice";

const ShopSidebar = ({
  sideSpaceClass,
  filter,
  setFilter,
  setPriceRange,
  priceRange,
  setQueryProducts,
}) => {
  const { categories } = useSelector((state) => state.category);
  const { brands } = useSelector((state) => state.brand);
  const { carriers } = useSelector((state) => state.carrier);
  const { models } = useSelector((state) => state.models);
  const dispatch = useDispatch()

  return (
    <>
      <div className="sidebar-style-new">
        <div className="d-flex justify-content-between align-items-center mb-20">
          <div className="sidebar-title">Refine Results</div>
        </div>
        <div className={clsx("sidebar-style mb-30", sideSpaceClass)}>
          {
            (filter?.category.length > 0 ||
              filter?.brand.length > 0 ||
              filter?.carrier.length > 0 ||
              filter?.models.length > 0 ||
              priceRange[0] !== 1 ||
              priceRange[1] !== 10000)
            &&
            <button
              onClick={() => {
                setFilter({
                  category: [],
                  brand: [],
                  carrier: [],
                  models: []
                });
                dispatch(setmodels([]))
                setQueryProducts(null);
                setPriceRange([1, 10000]);
              }}
              className="filter-btn ms-auto"
            >
              Remove Filter{" "}
            </button>
          }

          <ShopCategoriesFilter
            data={categories}
            title="Categories"
            setFilter={setFilter}
            filter={filter}
          />
          <ShopCategoriesFilter
            data={brands}
            title="Brands"
            setFilter={setFilter}
            filter={filter}
          />

          <ShopCategoriesFilter
            data={carriers}
            title="Carriers"
            setFilter={setFilter}
            filter={filter}
          />

          <ShopPrice
            title="Price"
            setPriceRange={setPriceRange}
            priceRange={priceRange}
            sideSpaceClass={sideSpaceClass}
          />

        </div>
      </div>
    </>
  );
};

ShopSidebar.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
