const { createSlice } = require("@reduxjs/toolkit");

const taxSlice = createSlice({
  name: "tax",
  initialState: {
    taxes: [],
  },
  reducers: {
    getTaxes(state, action) {
      state.taxes = action.payload;
    },
  },
});

export const { getTaxes } = taxSlice.actions;
export default taxSlice.reducer;
