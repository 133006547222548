import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { calculateTaxAmount, getDiscountPrice } from "../../../helpers/product";
import { decreaseQuantity, deleteFromCart, increaseQuantity, decreaseRetailerQuantity,increaseRetailerQuantity, removeFromRetailerCart } from "../../../store/slices/cart-slice"

const MenuCart = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);

  const { user } = useSelector((state) => state.auth);
  const { taxes } = useSelector((state) => state.tax);

  let totalDiscount = 0;
  let cartTotalPrice = 0;
  let cartTotalDiscountedPrice = 0;
  let tierDisount = 0;

  let userType = user?.type || "Consumer";
  // eslint-disable-next-line
  const Tax = taxes?.find((i) => {
    return i;
  });
  const handleCartTotalDiscount = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.quantity * ((((userType === "Retailer" ? item?.retailerDiscount || item?.discount : item?.discount) || 0) / 100) * (userType === "Retailer" ? item?.retailerPrice || item?.price : item?.price));
    });
    return total?.toFixed(2);
  }

  const getGrandTotal = (cartTotalDiscountedPrice, currency, Tax, cartTotalPrice) => {
    return cartTotalDiscountedPrice > 0 ? (
      currency.currencySymbol + ((+cartTotalDiscountedPrice?.toFixed(2)) +
        (userType === "Retailer" || userType === "Dropshipper" ?
          0 :
          +calculateTaxAmount(cartTotalDiscountedPrice, +Tax?.tax > 0 ? +Tax?.tax : 0)?.toFixed(2)))?.toFixed(2)

    ) : (
      currency.currencySymbol + ((+cartTotalPrice?.toFixed(2)) +
        (userType === "Retailer" || userType === "Dropshipper" ?
          0 :
          +calculateTaxAmount(cartTotalPrice, +Tax?.tax > 0 ? +Tax?.tax : 0)?.toFixed(2)))?.toFixed(2)
    );
  }

  return (
    <>
      {
        cartItems && cartItems.length > 0 && (
          <div className="shopping-cart-content">
            {cartItems && cartItems.length > 0 ? (
              <Fragment>
                <ul>
                  {cartItems.map((item) => {

                    const finalProductPrice =
                      user?.type === "Retailer" ? item?.retailerPrice || item?.price : user?.type === "Dropshipper" ? item?.dropshipperPrice : item?.price;
                    const finalDiscount =
                      user?.type === "Retailer" ? item?.retailerDiscount || item?.discount || 0 : user?.type === "Dropshipper" ? item?.dropshipperDiscount || item?.discount : item?.discount;

                    const discountedPrice = getDiscountPrice(finalProductPrice, finalDiscount);
                    const finalDiscountedPrice = +(discountedPrice * currency.currencyRate)?.toFixed(2);
                    if ((user?.type === "Retailer" && !item.retailerDiscount) || (user?.type === "Dropshipper" && !item.dropshipperDiscount) || (user?.type === "Consumer" && !item.discount)) {
                      tierDisount = tierDisount + ((user?.type === "Retailer" ? item?.retailerPrice : item.price) / user?.tier?.percentage) * item?.quantity;
                    }

                    cartTotalDiscountedPrice += finalDiscountedPrice * item.quantity
                    cartTotalPrice += finalProductPrice * item.quantity;

                    totalDiscount += (finalProductPrice - finalDiscountedPrice) * item.quantity;
                    return (
                      <li className="single-shopping-cart" key={item.cartItemId}>
                        <div className="shopping-cart-img">
                          <Link to={process.env.PUBLIC_URL + "/product/" + item.id}>
                            <img
                              alt=""
                              src={process.env.PUBLIC_URL + item.images[0]}
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="shopping-cart-title">
                          <h4>
                            <Link
                              to={process.env.PUBLIC_URL + "/product/" + item.slug}
                            >
                              {" "}
                              {item.name}{" "}
                            </Link>
                          </h4>
                          {/* <h6>Qty: {item.quantity}</h6> */}
                          <td className="product-quantity">
                            <div className="cart-plus-minus-card">
                              <button
                                className="dec qtybutton"
                                onClick={() => {
                                  if (item !== undefined &&
                                    item.quantity > 1
                                  ) {
                                    user?.type === "Retailer" ? dispatch(decreaseRetailerQuantity(item)) : dispatch(decreaseQuantity(item))
                                  }
                                }
                                }
                              >
                                -
                              </button>
                              <input
                                className="cart-plus-minus-box"
                                type="text"
                                value={item.quantity}
                                readOnly
                              />
                              <button
                                className="inc qtybutton"
                                onClick={() => {
                                  if (item !== undefined &&
                                    item.quantity < item.stock
                                  ) {
                                    
                                    user?.type === "Retailer" ? dispatch(increaseRetailerQuantity(item)) : dispatch(increaseQuantity(item))
                                  }
                                }
                                }
                                disabled={
                                  item !== undefined &&
                                  item.quantity >= item.stock
                                }
                              >
                                +
                              </button>
                            </div>
                          </td>
                          <span className="product-price-cart">
                            {discountedPrice !== null ? (
                              <Fragment>
                                <del>
                                  <span className="amount old">
                                    {currency.currencySymbol +
                                      finalProductPrice}
                                  </span>
                                </del>
                                &nbsp;
                                &nbsp;
                                <span className="amount">
                                  {currency.currencySymbol +
                                    finalDiscountedPrice}
                                </span>
                              </Fragment>
                            ) : (
                              <span className="amount">
                                {currency.currencySymbol +
                                  finalProductPrice}
                              </span>
                            )}
                          </span>
                          {item.selectedProductColor &&
                            item.selectedProductSize ? (
                            <div className="cart-item-variation">
                              <span>Color: {item.selectedProductColor}</span>
                              <span>Size: {item.selectedProductSize}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="shopping-cart-delete">
                          <button onClick={() => {
                             if (user?.type === "Retailer") {
                              dispatch(removeFromRetailerCart(item.cartItemId));
                            } else {
                              dispatch(deleteFromCart(item.cartItemId));
                          }
                            
                          }}>
                            <i className="fa fa-times-circle" />
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="shopping-cart-total">
                  <h4 className="grand-totall-title">
                    Products
                    <span>
                      {currency.currencySymbol + cartTotalPrice?.toFixed(2)}
                    </span>
                  </h4>
                  {
                    totalDiscount > 0 ? (
                      <h4 className="grand-totall-title">
                        Discount
                        <span>
                          {currency.currencySymbol + handleCartTotalDiscount()}
                        </span>
                      </h4>
                    ) : (null)
                  }
                  {
                    cartTotalDiscountedPrice > 0 ? (
                      <h4 className="grand-totall-title">
                        After Discount
                        <span>
                          {currency.currencySymbol + cartTotalDiscountedPrice?.toFixed(2)}
                        </span>
                      </h4>) : (null)
                  }
                  {
                    tierDisount > 0 ? (
                      <h4 className="grand-totall-title">
                        Tier Discount
                        <span>
                          {currency.currencySymbol + tierDisount?.toFixed(2)}
                        </span>
                      </h4>
                    ) : (null)
                  }
                  {
                    userType !== "Retailer" && userType !== "Dropshipper" ? (
                      <h4 className="grand-totall-title">
                        Tax
                        <span>
                          {
                            cartTotalDiscountedPrice > 0 ? (
                              currency.currencySymbol +
                              calculateTaxAmount(
                                cartTotalDiscountedPrice,
                                +Tax?.tax > 0 ? +Tax?.tax : 0
                              )?.toFixed(2)
                            ) : (
                              currency.currencySymbol +
                              calculateTaxAmount(
                                cartTotalPrice,
                                +Tax?.tax > 0 ? +Tax?.tax : 0
                              )?.toFixed(2)
                            )
                          }
                        </span>
                      </h4>
                    ) : (null)
                  }

                  <h4 className="grand-totall-title">
                    Grand Total
                    <span>
                      {
                        getGrandTotal(cartTotalDiscountedPrice, currency, Tax, cartTotalPrice)
                      }
                    </span>
                  </h4>
                </div>
                <div className="shopping-cart-btn btn-hover text-center">
                  <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
                    view cart
                  </Link>
                  {/* <Link
                    className="default-btn"
                    to={process.env.PUBLIC_URL + "/checkout"}
                  >
                    checkout
                  </Link> */}
                </div>
              </Fragment>
            ) : (
              <p className="text-center">No items added to cart</p>
            )}
          </div>
        )
      }
    </>
  );
};

export default MenuCart;