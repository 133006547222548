import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import SEO from "../../components/seo";
import LayoutOne from '../../layouts/LayoutOne';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import { fetchFilteredProduct } from '../../store/actions/productActions';

const Filtered = () => {
    const dispatch = useDispatch();

    const [layout, setLayout] = useState('grid three-column');
    const [showFilter, setShowFilter] = useState(false);
    let { products, filter } = useSelector((state) => state.product);

    const getLayout = (layout) => {
        setLayout(layout)
    }

    useEffect(() => {
        dispatch(fetchFilteredProduct('blank'));
    }, [dispatch]);

    useEffect(() => {
        setShowFilter(true);
    }, []);

    return (
        <Fragment>
            <SEO
                titleTemplate="Remax | Shop"
                description="Deal in all kind of accessories."
            />

            <LayoutOne headerTop="visible" headerContainerclassName="container-fluid" headerPaddingclassName="header-padding-1">
                {/* breadcrumb */}
                {
                    products ? (
                        <>
                            <div className="shop-area pt-95 pb-100">
                                <div className="container-fluid product-list">
                                    {
                                        showFilter && (<span className='side-filters-overalay'></span>)
                                    }

                                    <div className={
                                        showFilter ? "side-filters active" : "side-filters"
                                    }>
                                        <ShopSidebar sideSpaceclassName="-30" setShowFilter={setShowFilter} route="filter" />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 order-1 order-lg-2">
                                            <div className='d-flex justify-content-between'>
                                                <button className="filter-btn" onClick={() => { setShowFilter(true) }}>Filters</button>
                                                {/* <button className="filter-btn" onClick={() => { }}>Clear Filters</button> */}
                                                <ShopTopbar getLayout={getLayout} productCount={filter.count} sortedProductCount={products.length} />
                                            </div>

                                            <ShopProducts layout={layout} products={filter?.products} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (<>
                        <h1>No Product Found</h1>
                    </>)
                }

            </LayoutOne>
        </Fragment>
    )
}

export default Filtered;