import axios from "axios";
import { getTaxes } from "../slices/tax-slice";

const API_URL = process.env.REACT_APP_API_HOST;

export const fetchTaxes = () => {
  return async (dispatch) => {
    const response = await axios.get(`${API_URL}/tax`);
    dispatch(getTaxes(response.data));
    return response.data;
  };
};
