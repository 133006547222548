const { createSlice } = require('@reduxjs/toolkit');

const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        brands: [],
    },
    reducers: {
        setbrands(state, action) {
            state.brands = action.payload;
        }
    },
});

export const { setbrands } = brandSlice.actions;
export default brandSlice.reducer;
