import { setCarriers } from "../slices/carrier-slice";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_HOST;

export const fetchCarriers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/carrier`);
      dispatch(setCarriers(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
