import axios from 'axios';
import { setAds, loadingAds } from '../slices/ads-slice';

const API_URL = process.env.REACT_APP_API_HOST

export const getAds = () => {
    return async dispatch => {
        try {
            dispatch(loadingAds(true))
            await axios.get(`${API_URL}/ads`).then(
                (res) => {
                    dispatch(setAds(res.data))
                }
            ).catch(
                (err) => {
                    loadingAds(false)
                }
            )
        } catch (error) {
            loadingAds(false)
        }
    }
}

