const { createSlice } = require('@reduxjs/toolkit');

const modelSlice = createSlice({
    name: 'models',
    initialState: {
        models: [],
    },
    reducers: {
        setmodels(state, action) {
            state.models = action.payload;
        }
    },
});

export const { setmodels } = modelSlice.actions;
export default modelSlice.reducer;
